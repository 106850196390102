/* src/Components/Projects.css */
.projects {
  text-align: center;
  padding: 60px 20px;
}

.projects h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.projects p {
  font-size: 1.125rem;
  color: #ccc;
  margin-bottom: 30px;
}

.projects .project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.projects .project-card {
  background: #222;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
}

.projects .project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
}

.projects .project-card h3 {
  color: #6200ea;
  margin-bottom: 10px;
}

.projects .project-card p {
  font-size: 1rem;
  color: #ccc;
}

@media (max-width: 768px) {
  .projects h1 {
    font-size: 2rem;
  }

  .projects p {
    font-size: 1rem;
  }

  .projects .project-card {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .projects h1 {
    font-size: 1.75rem;
  }

  .projects p {
    font-size: 0.9rem;
  }

  .projects .project-card {
    padding: 12px;
  }
}
