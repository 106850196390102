/* src/Components/Contact.css */

.contact {
  text-align: center;
  padding: 40px 20px;
  background: #222;
  border-radius: 12px;
  margin: 0 auto;
  width: 80%;
  max-width: 600px; /* Ensures a maximum width on large screens */
}

.contact h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #fff;
}

.contact p {
  font-size: 1.125rem;
  color: #ccc;
  margin-bottom: 20px;
}

.contact a {
  display: block;
  color: #6200ea;
  margin: 10px 0;
  font-size: 1.125rem;
  text-decoration: none;
  transition: color 0.3s;
}

.contact a:hover {
  color: #fff;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .contact {
    padding: 30px 20px;
    width: 90%;
  }

  .contact h1 {
    font-size: 2rem;
  }

  .contact p {
    font-size: 1rem;
  }

  .contact a {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .contact {
    padding: 25px 15px;
    width: 95%;
  }

  .contact h1 {
    font-size: 1.75rem;
  }

  .contact p {
    font-size: 0.9rem;
  }

  .contact a {
    font-size: 0.9rem;
  }
}
