/* src/App.css */

body {
  font-family: 'Poppins', sans-serif;
  background-color: #1a1a1a;
  color: #fff;
  margin: 0;
  padding: 0;
}

.App {
  display: block; /* Remove flex from App to avoid dividing into 3 sections */
  min-height: 100vh;
}

/* Sidebar */
.sidebar {
  width: 260px;
  background: #111;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow-y: auto;
  transition: width 0.3s ease;
}

.sidebar a {
  color: #fff;
  margin: 15px 0;
  text-decoration: none;
  font-size: 1.125rem;
  transition: color 0.3s ease;
}

.sidebar a:hover {
  color: #6200ea;
}

/* Main Content */
.main-content {
  margin-left: 260px; /* Avoid overlap with the sidebar */
  padding: 40px;
  background-color: #1a1a1a;
  min-height: calc(100vh - 80px); /* Make sure content adjusts within viewport height */
  transition: margin-left 0.3s ease;
}

@media (max-width: 1024px) {
  /* For tablets */
  .sidebar {
    width: 200px;
  }

  .main-content {
    margin-left: 200px;
  }
}

@media (max-width: 768px) {
  /* For small tablets and large phones */
  .sidebar {
    width: 60px; /* Sidebar becomes a narrow vertical menu */
  }

  .main-content {
    margin-left: 60px;
    padding: 20px;
  }

  .sidebar a {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  /* For mobile devices */
  .sidebar {
    width: 0; /* Hide sidebar */
    display: none;
  }

  .main-content {
    margin-left: 0;
    padding: 15px;
  }

  /* Optionally, you can add a hamburger menu or toggle button to open the sidebar */
}
