/* Sidebar Container */
.sidebar {
  width: 260px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #111;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 2px 0 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  z-index: 1000;
}

/* Logo Styles */
.logo-container {
  text-align: center;
  margin-bottom: 40px;
}

.logo {
  color: #6200ea;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  padding: 10px;
  text-transform: uppercase;
  word-break: break-word;
  line-height: 1.3;
}

/* Navigation Menu */
.nav-menu {
  width: 100%;
}

.nav-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-menu li {
  margin: 15px 0;
}

.nav-menu a {
  display: block;
  padding: 12px 20px;
  background-color: #222;
  color: #fff;
  text-decoration: none;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
  transition: all 0.3s ease;
}

.nav-menu a:hover {
  background-color: #6200ea;
  transform: scale(1.05);
}

.nav-menu a.active {
  background-color: #6200ea;
  transform: scale(1.05);
}

/* Mobile Menu Button */
.mobile-menu-button {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1001;
  padding: 10px;
  background-color: #111;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: none;
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 999;
}

.overlay.active {
  display: block;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
    width: 200px;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .logo {
    font-size: 18px;
  }

  .nav-menu a {
    font-size: 14px;
    padding: 10px 15px;
  }

  .mobile-menu-button {
    display: block;
  }
}

/* Small Mobile Styles */
@media (max-width: 480px) {
  .sidebar {
    width: 180px;
  }

  .logo {
    font-size: 16px;
  }

  .nav-menu a {
    font-size: 13px;
    padding: 8px 12px;
  }
}