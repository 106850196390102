/* src/Components/Skills.css */

.skills {
  text-align: center;
  padding: 60px 20px;
}

.skills h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.skills ul {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.skills ul li {
  background: #222;
  padding: 12px 24px;
  border-radius: 8px;
  color: #ccc;
  font-size: 1rem;
  transition: transform 0.3s, background 0.3s;
}

.skills ul li:hover {
  background: #6200ea;
  color: #fff;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .skills h1 {
    font-size: 2rem;
  }

  .skills ul li {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .skills h1 {
    font-size: 1.75rem;
  }

  .skills ul {
    gap: 15px;
  }

  .skills ul li {
    font-size: 0.875rem;
    padding: 8px 16px;
  }
}
