/* src/footer.css */

footer {
  background: #111;
  color: #777;
  text-align: center;
  padding: 20px 0;
  font-size: 1rem;
  position: relative; /* Keeps footer at the bottom */
  width: 100%;
  bottom: 0;
}

footer p {
  margin: 0;
}

@media (max-width: 768px) {
  footer {
    padding: 15px 0;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  footer {
    padding: 10px 0;
    font-size: 0.8rem;
  }
}
