/* src/Components/Hero.css */
.hero {
  text-align: center;
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero h1 span {
  color: #6200ea;
}

.hero p {
  font-size: 1.125rem;
  color: #ccc;
  line-height: 1.6;
  max-width: 600px;
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .hero {
    padding: 60px 15px;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 0.9rem;
    margin: 0 10px;
  }
}
